<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/user-courses">
                    <i class="fa fa-book" aria-hidden="true"></i>Introduction to Electric Vehicles
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/battery-technology">
                    <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
          </nav>
                
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Female Engineers Academy - Assessment 1 Schedule</h1>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <h4>Assessment 1 will be taken on Tuesday,23rd February,2021</h4>
                </div>
                <div class="card-body">
                  <p class="card-text">Introduction to Electric Vehicles  - 10 am to 11 am
                     <a class="assess" href="http://bit.ly/Introduction-to-electric-vehicles" target="_blank">Click Here To Take The Assessment</a>
                  </p>
                  <p class="card-text">Design and Manufacturing  -  12 pm to 2 pm
                    <a class="assess" href="http://bit.ly/Design-and-Manufacturing" target="_blank">Click Here To Take The Assessment</a>
                  </p>
                  <p class="card-text">Battery Technology -  3 pm to 4 pm
                    <a class="assess" href="http://bit.ly/Battery-Technology" target="_blank">Click Here To Take The Assessment</a>
                  </p>
                  <button type="submit"
                  class="btn solarorange" 
                  style="color: #0D96E2;background-color: white; width: 35%;margin-top: 5%;"
                  (click)="completed()"
                  >Mark Completed</button>
                </div>
              </div>
            </div>
          </div>

         

  
        </div>
      </main>
  
    </div>
  </div>


