      <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <!-- <div class="container"> -->
          <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button> -->

          <a routerLink="/home" class="navbar-brand mr-auto"><img src="../../../assets/images/Solar Academy 2.png" 
          width="100" height="100" alt="SolarTaxi Logo"/></a>
          
          <!-- <div class="collapse navbar-collapse" id="navbarNav"> -->
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item"><a routerLink="/gallery" class="nav-link"><span class="fa fa-picture-o fa-lg"></span>Gallery</a></li>
              <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><span class="fa fa-list fa-lg"></span>Dashboard</a></li>
              <li class="nav-item"><a routerLink="/login" class="nav-link"><span class="fa fa-sign-out fa-lg"></span>Log In</a></li>
              <!-- <li class="nav-item"><a routerLink="/login" class="nav-link">Dashboard</a></li> -->
              <!-- <li class="nav-item"><a href="#enroll" class="nav-link">Courses</a></li> -->
            </ul>
            <!-- <span class="navbar-text">
                data-target="#loginModal"
              <a data-toggle="modal" routerLink="/login">
                  <span class="fa fa-sign-in"></span> LOGIN
              </a>
          </span> -->
          <!-- </div> -->
        <!-- </div> -->
      </nav>

          <!-- login modal -->
    <div id="loginModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-lg" role="content">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Login</h4>
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                  <form #form="ngForm" (ngSubmit)="login(form)">
                      <div class="form-row">
                          <div class="form-group col-sm-4">
                                  <label class="sr-only" for="email">Email address</label>
                                  <input type="text" class="form-control form-control-sm mr-1" id="email" placeholder="Enter email" [(ngModel)]="data.email" required>
                          </div>
                          <div class="form-group col-sm-4">
                              <label class="sr-only" for="password">Password</label>
                              <input type="password" class="form-control form-control-sm mr-1" id="password" placeholder="Password" [(ngModel)]="data.password" required>
                          </div>
                          <div class="col-sm-auto">
                              <div class="form-check">
                                  <input class="form-check-input" type="checkbox">
                                  <label class="form-check-label"> Remember me
                                  </label>
                                  <br>
                                 <a routerLink="/forgot-password">Forgot Password?</a>
                              </div>
                          </div>
                      </div>
                      <div class="form-row">
                          <button type="button" class="btn btn-secondary btn-sm ml-auto" data-dismiss="modal">Cancel</button>
                          <button type="submit" class="btn btn-primary btn-sm ml-1">Sign in</button>        
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  