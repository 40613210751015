import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-assessment1',
  templateUrl: './assessment1.component.html',
  styleUrls: ['./assessment1.component.css']
})
export class Assessment1Component implements OnInit {
  data = {
    module : "Assessment 1"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
   
  }

  async completed() {
    
    let courseCompleted = {
      assessment1: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/electrical-system']);
  }

}
