import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import firebase  from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
// import { newUser } from '../model/newUser';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: User; // Save logged in user data
  user: Observable<User>;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore szervice
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {    
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        localStorage.setItem('useremail', this.userData.email);
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('useremail', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then((result) => {
        alert('Hi, you have successfully logged in.');
        this.ngZone.run(() => {
          // this.router.navigate(['home']);
        });
        this.SigninData(result.user);
        // this.router.navigate(['dashboard']);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        console.log(result)
        this.SendVerificationMail();
        // this.SetUserData(result.user);
        window.location.reload();
      }).catch((error) => {
        // window.alert(error.message)
        console.log(error.message); //what is wrong with the signup popup error
      })
  }

  // Send email verfificaiton when new user sign up
 async SendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['user']);
        })
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error)
    })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(webUser) {

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${webUser.userEmail}`);
    const userData = {
      createdAt: new Date(),
      uid: webUser.uid,
      email: webUser.userEmail,
      displayName: webUser.userName,
      photoURL: webUser.photoURL,
      phoneNumber: webUser.phoneNumber,
      emailVerified: webUser.emailVerified,
    }
    return userRef.set(userData, {
      merge: true
    })
    
  }

  SigninData(user) {

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.email}`);
    const signinData = {
      uid: user.uid,
      emailVerified: user.emailVerified
    }
    return userRef.set(signinData, {
      merge: true
    })
  }

  // Sign out 
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('useremail');
      this.router.navigate(['login']);
    })
  }

}
