import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CourseService } from 'src/app/shared/services/course.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/shared/services/users.service';


@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {
  id;
  course;
  courseId;
  module;
  closeResult: string;
  values;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private usersService: UsersService,
    private modalService: NgbModal
  ) { 
    this.courseId = JSON.parse(localStorage.getItem('course'));
    this.id = this.route.snapshot.paramMap.get('moduleId');
    if(this.courseId) this.courseService.getCourse(this.courseId).pipe(take(1))
    .subscribe(c => this.course = c)

    
    if(this.id) this.courseService.getModule(this.courseId,this.id).pipe(take(1))
    .subscribe(m => this.module = m)
    console.log(this.courseId)
    console.log(this.id)
  }

  ngOnInit(): void {
    this.usersService.totalUsers()
    .subscribe( values => this.values = values.length);
  }

  watchVideo(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


}
