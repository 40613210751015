<app-navbar></app-navbar>

<main>
  <div class="stage">
    <div class="nextlstage">
        <button class="login" routerLink="/login">Log In</button>
        <!-- <button class="signup" routerLink="/signup">Sign Up</button> -->

        <p class="lsmessage">Welcome Back to Solar Academy</p>
        <form #form="ngForm" (ngSubmit)="login(form)">
          <input type="text" class="form-control" id="email" name="email" placeholder="Email Address" [(ngModel)]="data.email" required> 
          
          <div class="input-group">
            <input type="password" class="form-control pswinput" id="password"
            [type]="fieldTextType ? 'text' : 'password'" 
             name="password" placeholder="Password" [(ngModel)]="data.password" required>
             <!-- <div class="input-group-append"> -->
              <span class="input-group-text">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !fieldTextType,
                    'fa-eye': fieldTextType
                  }"
                  (click)="toggleFieldTextType()"
                ></i>
              </span>
            <!-- </div> -->
          </div>
          
          <button type="submit" class="loginbutton mt-3">Log In</button>
        </form>
        <br>
        <a routerLink="/forgot-password">Forgot Password?</a>
    </div>
  </div>
</main>