import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userId;
  user;

  constructor(
    public authService: AuthService,
    public dashboardService: DashboardService
  ) {
    this.userId = localStorage.getItem('useremail');
    console.log(this.userId)

    if(this.userId) this.dashboardService.getUser(this.userId).pipe(take(1))
    .subscribe(user => this.user = user)
   }

  ngOnInit(): void {
  }

}
