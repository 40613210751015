import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-usercourses',
  templateUrl: './usercourses.component.html',
  styleUrls: ['./usercourses.component.css']
})
export class UsercoursesComponent implements OnInit {
data = {
  module : 'Electric Cars'
}
  constructor(
    public authService: AuthService,
    private usersService: UsersService,
    private router : Router
  ) { }


  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      moduleintro: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/design-and-manufacturing']);
  }

}
