<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/electric-cars">
                    <i class="fa fa-book" aria-hidden="true"></i>Electric Cars
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/iot">
                    <i class="fa fa-book" aria-hidden="true"></i>Internet of Things
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/charger-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Charger Installation 
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Installation 
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/welding">
                    <i class="fa fa-book" aria-hidden="true"></i>Welding 
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/battery-technology">
                    <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
        </nav>
                
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">FEA - Auto Electricals of an Electric Scooter</h1>
          </div>


          <!-- second video -->
          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>01  </span>Auto Electricals of an Electric Scooter</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FAuto-Electricals-of-an-Electric-Scooter-Gifty-Afun%2FGifty%20(Auto%20Electical%20of%20An%20Electric%20Scooter).mp4?alt=media&token=1dbca1a4-acbf-4a48-9693-095ac09cac5a" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>02  </span>Battery Management System</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FAuto-Electricals-of-an-Electric-Scooter-Gifty-Afun%2FGifty%20(%20Battery%20Management%20System).mp4?alt=media&token=5b647773-4d6d-4a09-8f5c-c28641b46f20" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>03  </span>Electric Speed Controller</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FAuto-Electricals-of-an-Electric-Scooter-Gifty-Afun%2FGifty%20(Electronic%20Speed%20Controller).mp4?alt=media&token=6e0bafc6-b0f0-4d0a-99b0-438630a63b93" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>04  </span>Brushless DC Motor</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FAuto-Electricals-of-an-Electric-Scooter-Gifty-Afun%2FGifty%20(Brushless%20DC%20Motor).mp4?alt=media&token=ff970d87-c6cc-4f48-9ef2-50ad4c3ad672" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FAuto-Electricals-of-an-Electric-Scooter-Gifty-Afun%2FAUTO%20ELECTRICALS%20OF%20AN%20E-SCOOTER%20(1).pptx?alt=media&token=75c17ea4-7d10-4d6d-bf43-181a0a2bd98f" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn solarblue">
                <a href="https://forms.gle/TmWv7uYHoxA9SxLn8" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Take Assessment
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <button type="submit"
              class="btn solarorange" 
              style="color: white; width: 50%;margin-bottom: 5%;"
              (click)="completed()"
             >Mark Completed</button>
          </div>
          </div>
         
        </div>
      </main>
  
    </div>
  </div>
