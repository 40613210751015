import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-battech',
  templateUrl: './battech.component.html',
  styleUrls: ['./battech.component.css']
})
export class BattechComponent implements OnInit {
  data = {
    course : "Battery Technology"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      course2: this.data.course
    }
    await this.usersService.userProgress(courseCompleted);

    this.router.navigate(['/dashboard']);
    // this.router.navigate(['/assessment-1']);
  }

}
