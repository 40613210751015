<footer class="footer">
    <div class="container">
        <div class="row">             
            <div class="col-4 offset-1 col-sm-2">
                <h5>Links</h5>
                <ul class="list-unstyled">
                    <li><a href="https://www.solartaxi.co">SolarTaxi Ltd.</a></li>
                    <li><a href="https://www.deliver.solartaxi.co">Delivery Website</a></li>
                    <li><a href="https://solartaxifoundation.com">SolarTaxi Foundation</a></li>
                    <li><a href="https://play.google.com/store/apps/details?id=com.solartaxi&hl=en&gl=US">SolarTaxi App</a></li>
                </ul>
            </div>
            <div class="col-7 col-sm-5">
                <h5>SolarTaxi Limited</h5>
                <address>
                    <i class="fa fa-map-marker fa-lg"></i> 6 Naa Asia Rd,Laterbiokoshie,<br>
                   Accra, Ghana<br>
                  <i class="fa fa-phone fa-lg"></i> (+233) 59 410 2035<br>
                  <i class="fa fa-envelope fa-lg"></i> <a href="mailto:support@solartaxi.co"> support@solartaxi.co</a>
               </address>
            </div>
            <div class="col-12 col-sm-4 align-self-center">
                <div class="text-center">
                    <a class="btn btn-social-icon btn-instagram" href="https://www.instagram.com/solartaxigh"><i class="fa fa-instagram"></i></a>
                    <a class="btn btn-social-icon btn-facebook" href="https://web.facebook.com/SolarTaxiGh"><i class="fa fa-facebook fa-lg"></i></a>
                    <a class="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/company/SolarTaxi"><i class="fa fa-linkedin fa-lg"></i></a>
                    <a class="btn btn-social-icon btn-twitter" href="https://www.twitter.com/SolarTaxiGh"><i class="fa fa-twitter fa-lg"></i></a>
                    <a class="btn btn-social-icon btn-google" href="https://www.youtube.com/channel/UCCGL5WbcQiPmW7Cb6bO0Emg"><i class="fa fa-youtube fa-lg"></i></a>
                    <a class="btn btn-social-icon" href="mailto:support@solartaxi.co"><i class="fa fa-envelope-o fa-lg"></i></a>
                </div>
            </div>
       </div>
       <div class="row justify-content-center">             
            <div class="col-auto">
                <p><i class="fa fa-copyright"></i> 2021 SolarTaxi Limited. All Rights Reserved</p>
            </div>
       </div>
    </div>
</footer>
