import { NgModule } from '@angular/core';
// Required services for navigation
import { Routes, RouterModule } from '@angular/router';
import { CatalogComponent } from 'src/app/components/catalog/catalog.component';
import { ConfirmPaymentComponent } from 'src/app/components/confirm-payment/confirm-payment.component';
import { CourseComponent } from 'src/app/components/course/course.component';
import { Assessment1Component } from 'src/app/components/courses/assessment1/assessment1.component';
import { Assessment2Component } from 'src/app/components/courses/assessment2/assessment2.component';
import { Assessment3Component } from 'src/app/components/courses/assessment3/assessment3.component';
import { AutoElectricalOfAnElectricScooterComponent } from 'src/app/components/courses/auto-electrical-of-an-electric-scooter/auto-electrical-of-an-electric-scooter.component';
import { BattechComponent } from 'src/app/components/courses/battech/battech.component';
import { ChargerInstallationComponent } from 'src/app/components/courses/charger-installation/charger-installation.component';
import { DesmaComponent } from 'src/app/components/courses/desma/desma.component';
import { ElesyComponent } from 'src/app/components/courses/elesy/elesy.component';
import { IotComponent } from 'src/app/components/courses/iot/iot.component';
import { SafetyComponent } from 'src/app/components/courses/safety/safety.component';
import { ScsComponent } from 'src/app/components/courses/scs/scs.component';
import { SolarComponent } from 'src/app/components/courses/solar/solar.component';
import { WeldingComponent } from 'src/app/components/courses/welding/welding.component';

// Import all the components for which navigation service has to be activated
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { GalleryComponent } from 'src/app/components/gallery/gallery.component';
import { HomeComponent } from 'src/app/components/home/home.component';
import { HomepageComponent } from 'src/app/components/homepage/homepage.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { UsercoursesComponent } from 'src/app/components/usercourses/usercourses.component';
import { VerifyEmailComponent } from 'src/app/components/verify-email/verify-email.component'; 
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { SecureInnerPagesGuard } from 'src/app/shared/guard/secure-inner-pages.guard.ts.guard';

const routes: Routes = [
  { 
   path: '',
   redirectTo: '/home', 
   pathMatch: 'full'
  },
  { 
    path: 'dashboard', 
    component: DashboardComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'electric-cars',
    component: UsercoursesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'design-and-manufacturing',
    component: DesmaComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'battery-technology',
    component: BattechComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'iot',
    component: IotComponent,
    canActivate: [AuthGuard]
  },{
    path: 'charger-installation',
    component: ChargerInstallationComponent,
    canActivate: [AuthGuard]
  },{
  //   path: 'electrical-system',
  //   component: ElesyComponent,
  //   canActivate: [AuthGuard]
  // }, {
  //   path: 'speed-control-system',
  //   component: ScsComponent,
  //   canActivate: [AuthGuard]
  // }, {
  //   path: 'solar-power-design',
  //   component: SolarComponent,
  //   canActivate: [AuthGuard]
  // }, {
  //   path: 'workshop-safety',
  //   component: SafetyComponent,
  //   canActivate: [AuthGuard]
  // },{
    path: 'auto-electrical',
    component: AutoElectricalOfAnElectricScooterComponent,
    canActivate: [AuthGuard]
  },{
    path: 'solar-installation',
    component: SolarComponent,
    canActivate: [AuthGuard]
  },{
      path: 'welding',
      component: WeldingComponent,
      canActivate: [AuthGuard]
  },{
    path: 'assessment-1',
    component: Assessment1Component,
    canActivate: [AuthGuard]
  }, {
    path: 'assessment-2',
    component: Assessment2Component,
    canActivate: [AuthGuard]
  }, {
    path: 'assessment-3',
    component: Assessment3Component,
    canActivate: [AuthGuard]
  },
  { 
    path: 'forgot-password', 
    component: ForgotPasswordComponent, 
    canActivate: [SecureInnerPagesGuard] 
  },
  { 
    path: 'verify-email-address', 
    component: VerifyEmailComponent, 
    canActivate: [SecureInnerPagesGuard] 
  },
  {
    path: 'home',
    component: HomepageComponent,
  },
  {
    path: 'oldhome',
    component: HomeComponent
  },
  {
    path: 'courses/:id',
    component: CatalogComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'courses/:id/course/:moduleId',
    component: CourseComponent,
  },
  {
    path: 'confirm-payment',
    component: ConfirmPaymentComponent
  },
  {
    path: 'gallery',
    component: GalleryComponent
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
