    <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
      <nav class="col-md-2 d-md-block bg-light sidebar">
        <div>
          <a routerLink="/home" class="navbar-brand"><img src="../../../assets/images/Solar Academy 2.png" width="100" height="100" alt="SolarTaxi Logo"/></a>
        </div>
        <div class="sidebar-sticky">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link active" routerLink="/dashboard">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>User Profile
              </a>
            </li>
            <!-- Calling SignOut() Api from AuthService -->
            <li class="nav-item">
              <a class="nav-link" (click)="authService.SignOut()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>Log out
              </a>
            </li>
          </ul>
        </div>
      </nav>
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">User Profile</h1>
          <!-- Show user data when logged in -->
          <div class="row">
            <div class="col-md-11 offset-1">
              <div class="media">
                </div>
                <!-- <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '../../../../../assets/images/SolarProfileLogo.png"
                  alt="{{user.displayName}}"> -->
                <div class="media-body">
                  <h1>Hello: <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h1>
                  <p>Email: <strong>{{ user.email }}</strong></p>
                  <p>User Number: <strong>{{ user.phoneNumber }}</strong></p>
                  <div class="alert alert-info" role="alert"> Hi {{(user.displayName) ? user.displayName : 'User'}}, please take
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdVjIjUNtfXXL95gfNHygQch4i0onJx9ueK9MWdvPXXgiV-8w/viewform" class="alertcolor"
                      target="_blank" rel="noopener noreferrer">
                          design and manufacturing assessment 
                      </a>if you have not. Thank you.
                  </div>
                  <div class="alert alert-danger">Take 
                    <a href="https://forms.gle/TmWv7uYHoxA9SxLn8" class="alertcolor" target="_blank" rel="noopener noreferrer">
                      Auto Electricals Assessment 
                    </a>if you have not. Thank you.
                  </div>
                  <div class="alert alert-warning">Take 
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdjl9_O7TiH6qOyvs8QoCpt3TzSaIuT_beGh3b7YBIe1yVKXw/viewform" class="alertcolor" target="_blank" rel="noopener noreferrer">
                      IOT Assessment 
                    </a>if you have not. Thank you.
                  </div>
                  <div class="alert alert-success">Take 
                    <a href="https://forms.gle/kXPaWLKQeRix9tx37" class="alertcolor" target="_blank" rel="noopener noreferrer">
                      Electric Car Assessment 
                    </a>if you have not. Thank you.
                  </div>
                  <div class="alert alert-primary">Take 
                    <a href="https://forms.gle/nNidS4SiZp1qrrJY6" class="alertcolor" target="_blank" rel="noopener noreferrer">
                      Solar & Charger Installation Assessment 
                    </a>if you have not. Thank you.
                  </div>
                  <div class="alert alert-secondary">Take 
                    <a href="https://forms.gle/XfGidDPyAFfzMV6D6 " class="alertcolor" target="_blank" rel="noopener noreferrer">
                      Welding Assessment 
                    </a>if you have not. Thank you.
                  </div>
                  <!-- <p>Email Verified: <strong>{{ user.emailVerified }}</strong></p> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content">
            <div class="col-11 offset-1">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-sm-3 ">
                      <img style="float: left;" src="../../../assets/images/IMG_0478.jpg"
                       alt="Electric Vehicle" width="150" height="150">
                    </div>
                    <div class="col-12 col-sm-8">
                      <h4 class="card-title">Introduction to Electric Vehicle</h4>
                      <div class="progress">
                        <div class="progress-bar bg-solarblue" style="width:20%"></div>
                      </div>
                    </div>
                  </div>
                  <button class="btn solarblue" [routerLink]="['/electric-cars/']">Start</button>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </main>
  
    </div>
  </div>
  
