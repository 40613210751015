import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Course } from 'src/app/shared/model/Course';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { UsersService } from 'src/app/shared/services/users.service';
import AOS from 'aos';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  courses: Course[] = [];
  filteredCourses: Course[] = [];
  category: string;
  values;

  constructor(
    private coursesService: CoursesService,
    private usersService: UsersService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    AOS.init();
    this.populateCourses();

     this.usersService.totalUsers()
    .subscribe( values => this.values = values.length);
  }
 

  populateCourses() {
    this.coursesService.getAllCourses()
    .pipe(switchMap(courses => {
      this.courses = courses
      console.log(courses)
      return this.route.queryParamMap
    }))
    .subscribe(params => {
      this.category = params.get('category');
      this.applyFilter();
    })
  }

  private applyFilter() {
    this.filteredCourses = (this.category) ?
    this.courses.filter(p => p.category === this.category):
    this.courses;
  }



}
