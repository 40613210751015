import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private afs: AngularFirestore
  ) { }

  transaction(txref) {
    this.afs.collection('transactions').add({
      transactionId: txref
    });
  }
}
