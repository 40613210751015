import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor( private afs: AngularFirestore) { }

  getCourse(courseId){
    return this.afs.collection('courses').doc(courseId).valueChanges();
  }

  getModule(courseId,id){
    return this.afs.collection('courses').doc(courseId).collection('engineeringmodules').doc(id).valueChanges();
  }
}
