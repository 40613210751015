<app-navbar></app-navbar>
<div class="container">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
            <!-- <p>Wait!!! Complete this last step before closing your browser.
                Click on the signup button to redirect you to the registration page.
                Thank you.</p>

            <p>After signing up/registering, kindly check your email account inbox or spam folder
                for a message from SolarTaxi requesting you to verify your email address used for the registration.
                You can only log in and see content only when you click on the link to verify your email address.
            </p> -->
            
            <p>A receipt for the payment has been sent to your email address. Happy Learning!!!</p>
            

            <button type="submit" 
            class="btn solarorange" 
            (click)="confirm()">Sign Up</button>
        </div>
        <div class="col-1"></div>
    </div>
</div>