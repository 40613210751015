import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-elesy',
  templateUrl: './elesy.component.html',
  styleUrls: ['./elesy.component.css']
})
export class ElesyComponent implements OnInit {
  data = {
    module : "Electrical System"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      module3: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/speed-control-system']);
  }


}
