import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from '../shared/app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthService } from './shared/services/auth.service';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { FooterComponent } from './components/footer/footer.component';
import { CourseComponent } from './components/course/course.component';
import { ConfirmPaymentComponent } from './components/confirm-payment/confirm-payment.component';
import { UsercoursesComponent } from './components/usercourses/usercourses.component';
import { CoursenavbarComponent } from './components/coursenavbar/coursenavbar.component';
import { DesmaComponent } from './components/courses/desma/desma.component';
import { BattechComponent } from './components/courses/battech/battech.component';
import { Assessment1Component } from './components/courses/assessment1/assessment1.component';
import { ElesyComponent } from './components/courses/elesy/elesy.component';
import { ScsComponent } from './components/courses/scs/scs.component';
import { Assessment2Component } from './components/courses/assessment2/assessment2.component';
import { SolarComponent } from './components/courses/solar/solar.component';
import { SafetyComponent } from './components/courses/safety/safety.component';
import { Assessment3Component } from './components/courses/assessment3/assessment3.component';
import { CoursessidebarComponent } from './components/coursessidebar/coursessidebar.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AutoElectricalOfAnElectricScooterComponent } from './components/courses/auto-electrical-of-an-electric-scooter/auto-electrical-of-an-electric-scooter.component';
import { IotComponent } from './components/courses/iot/iot.component';
import { ModulelistnavComponent } from './components/modulelistnav/modulelistnav.component';
import { ChargerInstallationComponent } from './components/courses/charger-installation/charger-installation.component';
import { WeldingComponent } from './components/courses/welding/welding.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    CatalogComponent,
    CourseComponent,
    ConfirmPaymentComponent,
    UsercoursesComponent,
    CoursenavbarComponent,
    DesmaComponent,
    BattechComponent,
    Assessment1Component,
    ElesyComponent,
    ScsComponent,
    Assessment2Component,
    SolarComponent,
    SafetyComponent,
    Assessment3Component,
    CoursessidebarComponent,
    HomepageComponent,
    GalleryComponent,
    AutoElectricalOfAnElectricScooterComponent,
    IotComponent,
    ModulelistnavComponent,
    ChargerInstallationComponent,
    WeldingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
