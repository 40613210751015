import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.css']
})
export class SolarComponent implements OnInit {
  data = {
    module : "Solar Installation"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      module5: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/welding']);
  }

}
