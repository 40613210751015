import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninUser } from 'src/app/shared/model/SiginUser';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  fieldTextType: boolean;

  originalSigninUser : SigninUser = {
    email: '',
    password: ''
  }

  data:SigninUser = { ...this.originalSigninUser }

  constructor(
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  async login(form: NgForm) {
    await this.authService.SignIn(this.data.email, this.data.password)
    .then(() => window.location.reload());
    form.reset();
    this.router.navigate(['/dashboard']);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
