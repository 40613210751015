<nav class="navbar navbar-light bg-light">
    <div class="firstnav">
      <a routerLink="/home" class="navbar-brand"><img src="../../../assets/images/Solar Academy 2.png" width="100" height="100" alt="SolarTaxi Logo"/></a>
    </div>

    <div class="thirdnav">
        <ul>
            <li><a routerLink="/login" (click)="authService.SignOut()">Log Out</a></li>
            <li><a routerLink="/dashboard">User Profile</a></li>
            <li><a href="https://paystack.com/pay/FEA-Practical-Session-Payment">Practical Session Payment</a></li>
            <li><a href="https://join.slack.com/t/solartaxifema-yux1302/shared_invite/zt-ma683ylh-xyWlzVFRKeYeo5_mBLYXUA">Join Our Slack Channel</a></li>
        </ul>
    </div>
</nav>

  