import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from 'src/app/shared/services/transaction.service';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.css']
})
export class ConfirmPaymentComponent implements OnInit {

  constructor(
    private router: Router,
    public transactionService: TransactionService
  ) { }

  ngOnInit(): void {
  }

  confirm() {
            // var urlParams = new URLSearchParams(window.location.search);

            // let txref = urlParams.get('reference');
            // console.log(txref)
            // window.sessionStorage.setItem('transactionReference', txref);

            //   //post reference and purchase id
            // alert(`You have successfully made payment and your reference is ${txref}`)
            // this.transactionService.transaction(txref);
            // this.router.navigate(['/signup']);
            this.router.navigate(['/signup']);
  }

}
