<div>
    <app-navbar></app-navbar>
    <header class="jumbotron">
        <div class="container">
            <div class="row row-header">
                <div class="col-12 col-sm-6">
                    <h1 data-aos="fade-left" data-aos-duration="2000">Female Engineers & Riders Academy</h1>
                    <p data-aos="fade-right" data-aos-duration="2000">We take inspiration from the World's best professionals, and create a unique electric vehicle experience. Our best trained engineers 
                        will take you trough the field of Design and Manufacturing, Battery Technology, Electrical System and many more!</p>
                </div>
                <div class="col-12 col-sm-3 align-self-center">
                    <!-- <img src="../../../assets/images/SolarProfileLogo.png"  alt="Solartaxi logo" class="img-fluid"> -->
                </div>
                <!-- tooltip -->
                <div class="col-12 col-sm align-self-center">
                    <a role="button" class="btn btn-block nav-link solarblue"
                    data-toggle="tooltip" data-html="true"
                    title="Or Call us at +233 548316465" 
                    data-placement="bottom" href="#enroll" style="width: 90%;color: whitesmoke;font-weight: 400;">VIEW COURSES</a>
                </div>
                <div>
                </div>
            </div>
        </div>
    </header>
    <div class="containerbg">
        <div class="container">
            <div class="row row-content">
                <div class="col" data-aos="fade-up" data-aos-delay="100">
                    <div id="mycarousel" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <img class="d-block img-fluid"
                                    src="../../../assets/images/W1.jpg" alt="Academy">
                                <div class="carousel-caption d-none d-md-block overlay">
                                    <h2> Seeking to know more about electric vehicles? 
                                        <span class="badge solarorange">HOT</span> 
                                        <!-- <span class="badge badge-pill badge-default">Registration Fee - GHC20.00</span> -->
                                    </h2>
                                    <p class="d-none d-sm-block">Train with the best professionals in the country in the field of IoT,
                                        Battery Technology, Design and Manufacturing, Drive an Electric Vehicle and many more.</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="d-block img-fluid"
                                    src="../../../assets/images/SCS1.jpg" alt="Buffet">
                                <div class="carousel-caption d-none d-md-block overlay">
                                    <h2>Females Engineers Academy
                                        <span class="badge solarorange">NEW</span>
                                    </h2>
                                    <p class="d-none d-sm-block">
                                        The academy trains and equip trainees with the skills that help them develop the 
                                        capacity to design, assemble and maintain electric vehicles. All for just GHC20.00 per person.
                                    </p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="d-block img-fluid"
                                    src="../../../assets/images/DM.jpg" alt="Alberto">
                                <div class="carousel-caption d-none d-md-block overlay">
                                    <h2>Riders Academy</h2>
                                    <h4>Done on-site - GHC100</h4>
                                    <p class="d-none d-sm-block">The academy trains and equip trainees with the skills that help them develop the 
                                        capacity to ride/drive electric vehicles. All for just GHC100.00 per person.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- manually moving through the carousel -->
                        <ol class="carousel-indicators">
                            <li data-target="#mycarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#mycarousel" data-slide-to="1"></li>
                            <li data-target="#mycarousel" data-slide-to="2"></li>
                        </ol>

                        <!-- move slides to previous and next manually -->
                        <a class="carousel-control-prev" href="#mycarousel" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a class="carousel-control-next" href="#mycarousel" role="button" data-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <div class="menu-content pb-60 col-lg-10">
                    <div class="title text-center">
                        <h1 class="mb-10 head">Features that make Solar Academy Unique</h1>
                        <p class="subhead">
                            We offer you the opportunity to learn from home in the midst of this COVID-19
                            pandemic about electric vehicle.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 secvice-left">
                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>Electric Vehicle Knowledge Acquisition</h4>
                            <p>
                                We deliver your parcel at a flexible and convenient
                                time to your doorstep with solar-powered electric
                                vehicles. Delivery cost cut down. City movements
                                made easy, cheap and safe.
                            </p>
                        </div>
                    </div>
                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>
                            Practical Training
                            </h4>
                            <p>
                                After successful completion of the course you enroll in, 
                                you will be offered the opportunity to come for practical training 
                                at Solar Taxi Limited any without paying extra fee.
                            </p>
                        </div>
                    </div>
                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>Paid-Internship Opportunity</h4>
                            <p>
                                You will be given a two months paid-internship opportunity at Solar Taxi Limited.
                                You will learn how to assemble and maintain electric vehicles.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 secvice-right">
                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>Employment Opportunity</h4>
                            <p>
                                With the right attitude, values and practical experience
                                from the six months internship you could earn yourself a job 
                                in the leading electric vehicle company - Solar Taxi Limited.
                            </p>
                        </div>
                    </div>

                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>Climate Action</h4>
                            <p>
                            Learning and using about electric vehicles helps protect the environment through 
                            production and use of clean energy.
                            </p>
                        </div>
                    </div>
                    <div class="single-service d-flex flex-row pb-30">
                        <div class="icon">
                            <h1><i class="fa fa-circle-o" aria-hidden="true"></i></h1>
                        </div>
                        <div class="desc" data-aos="fade-up" data-aos-duration="4000">
                            <h4>Gender Equality</h4>
                            <p>
                                Engineering is a male dominated field but the Female Engineering Academy empowers
                                women to venture into this field and achieve their wild dreams.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

    <!-- courses from SolarTaxi -->
            <div class="row row-content" id="enroll">
                <div class="col-12">
                    <h3  class="headingtext">Top-Notch E-Mobility Courses Designed Specially For You</h3>
                        <ng-container *ngFor='let c of filteredCourses; let i=index'>
                            <div class="row">
                                <!-- col- is for extra small screens -->
                                <div class="col-12 col-sm-6" data-aos="flip-left" data-aos-delay="100">
                                <div class="card">
                                <img src="../../../assets/images/DM.jpg" alt="Avatar" style="width:100%">
                                <div class="card-body cbody">
                                <h4><b>{{ c.coursename }}</b></h4> 
                                <!-- <p>{{ c.description}}</p> -->
                                <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <p>Registration fee: GHC{{ c.price }}</p>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <p>Level: {{ c.skilllevel }}</p>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <p>Number Enrolled:{{ values-4 }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>Free Training Cost and Course Materials</p>
                                    </div>
                                </div>
                                <p><button [routerLink]="['/courses/',c.id]">PREVIEW MODULES</button></p>
                                </div>
                            </div> 
                        </div>
                                <div class="col-12 col-sm-6" data-aos="flip-right" data-aos-delay="100">
                                    <div class="card">
                                    <img src="../../../assets/images/Orange-Bike-Without-Screen-Side.jpg" alt="Avatar" style="width:100%">
                                    <div class="card-body cbody">
                                    <h4><b> Electric Vehicle Driving Academy</b></h4> 
                                    <!-- <p>{{ c.description}}</p> -->
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <p>Registration fee: GHC100</p>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <p>Level: Beginner</p>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <p>Number Enrolled:25</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p>Free Training Cost and Course Materials</p>
                                        </div>
                                    </div>
                                    <p><button>PREVIEW MODULES</button></p>
                                    </div>
                                </div> 
                                </div>
                            </div>
                            <div *ngIf="(i+1) % 2 === 0" class="w-100"></div>
                        </ng-container>
                </div>
            </div>


            <div class="row benefit">
                <div class="col-12" data-aos="fade-up" data-aos-delay="100">
                    <h2 class="text-center headingtext">Testimonials from our Academy Beneficiaries</h2>
                    <div id="accordion">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div class="card">
                                    <div class="card-header" role="tab" id="dannyhead">
                                        <img class="testimoialimg" src="../../../assets/images/Giftytestimonialpic.jpg" alt="Gifty's image">
                                            <h3 class="mb-0">
                                                <a data-toggle="collapse" data-target="#danny">
                                                    Gifty Pamela Afun <small>BSc. Electrical and Electronic Engineering, Engineer at SolarTaxi</small>
                                                </a>
                                            </h3>
                                        <div role="tabpanel" class="collapse show" id="danny" data-parent="#accordion">
                                            <div class="card-body">
                                                <p class="d-none d-sm-block">
                                                    <em>
                                                        I started on stage zero. With the Academy's courses
                                                        on Electric Vehicle, I have become innovative. I realized how easily electronics can be integrated 
                                                        into any system to get a new invention. A module like Design and Manufacturing has helped me gain 
                                                        broader knowledge on the effects of materials in manufacturing and production.
                                                    </em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="col-12 col-sm-4">
                                <div class="card">
                                    <div class="card-header" role="tab" id="agumbehead">
                                        <img class="testimoialimg" src="../../../assets/images/Dorothytestimonialpic.jpg" alt="Dorothy's image">
                                            <h3 class="mb-0">
                                                <a data-toggle="collapse" data-target="#agumbe">
                                                    Dorothy Eyetsa Fiatui <small>BSc. Petroleum Engineering, Engineer at SolarTaxi</small>
                                                </a>
                                            </h3>
                                        <div role="tabpanel" class="collapse show" id="agumbe" data-parent="#accordion">
                                            <div class="card-body">
                                                <p class="d-none d-sm-block"><em>
                                                    The Female Engineering Academy course and training has
                                                    given me the opportunity to develop great interest in the renewable energy industry.
                                                    It has also helped me to, for the first
                                                    time, gained a real experience on the field in engineering. This has landed me a job 
                                                    at Solar Taxi Limited where I am continually challenged to learn and contribute my quota
                                                    towards the company's vision.
                                                </em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="col-12 col-sm-4">
                                <div class="card">
                                    <div class="card-header" role="tab" id="albertohead">
                                        <img class="testimoialimg" src="../../../assets/images/Rebeccatestimonialpic.jpg" alt="Rebecca's image">
                                            <h3 class="mb-0">
                                                <a data-toggle="collapse" data-target="#alberto">
                                                    Rebecca Asiedu Anyamah <small>Industrial Maintenance, Engineer at SolarTaxi</small>
                                                </a>
                                            </h3>
                                        <div role="tabpanel" class="collapse show" id="alberto" data-parent="#accordion">
                                            <div class="card-body">
                                                <p class="d-none d-sm-block"><em>
                                                    Using electric vehicle helps protect the environment.
                                                    Thus, assembling electric bikes helps me contribute to the awareness
                                                    of encouraging the use of green energy.
                                                    Also, engineering is a male dominated field but the Female Engineering Academy
                                                    has given me the kind of empowerment that says everything can be achieved.
                                                </em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>