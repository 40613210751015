import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private afs: AngularFirestore) { }

// Calculating the number of students who have enrolled
totalUsers() {
  return this.afs.collection('users').valueChanges();
}


userProgress(courseCompleted) {
  let userId = localStorage.getItem('useremail');
  return this.afs.collection('users').doc(userId).
  collection('courseCompleted').doc('PDSXViuwLLtK8vLajMUw').set(courseCompleted, {
    merge: true
  })
}

}