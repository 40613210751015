import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-charger-installation',
  templateUrl: './charger-installation.component.html',
  styleUrls: ['./charger-installation.component.css']
})
export class ChargerInstallationComponent implements OnInit {

  data = {
    module : "Charger Installation"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      module5: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/solar-installation']);
  }
}
