<nav class="col-md-2 d-md-block bg-light sidebar">
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link active" href="#intro">
            <i class="fa fa-user-circle-o" aria-hidden="true">Introduction to Electric Vehicles</i>
          </a>
        </li>
        <!-- Calling SignOut() Api from AuthService -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/design-and-manufacturing">
            <i class="fa fa-sign-out" aria-hidden="true"></i>Design and Manufacturing
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/auto-electrical">
            <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/battery-technology">
            <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/assessment-1">
            <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/electrical-system">
            <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/speed-control-system">
            <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/assessment-2">
            <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/solar-power-design">
            <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/workshop-safety">
            <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Sefety Precautions
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/assessment-3">
            <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
          </a>
        </li> -->
      </ul>
    </div>
  </nav>

