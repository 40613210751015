<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/user-courses">
                    <i class="fa fa-book" aria-hidden="true"></i>Introduction to Electric Vehicles
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/iot">
                    <i class="fa fa-book" aria-hidden="true"></i>Internet of Things
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/charger-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Charger Installation 
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Installation 
                  </a>
                </li>
                <li class="nav-item">
                <a class="nav-link" routerLink="/welding">
                  <i class="fa fa-book" aria-hidden="true"></i>Welding 
                </a>
              </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/battery-technology">
                    <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
        </nav>
                
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Female Engineers Academy - Battery Technology</h1>
          </div>


          <!-- second video -->
          <div class="row" id="battech">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FBattery-Technology%2FBATTERY%20TECHNOLOGY%20(%20LITHIUM%20ION)_Prt%201.mp4?alt=media&token=e890c41a-e098-4e2d-8543-0900c3614071" type="video/mp4">
              </video>
            </div> 
          </div>

          <div class="row" id="battech">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FBattery-Technology%2FBATTERY%20TECHNOLOGY%20(%20LITHIUM%20ION)_Prt%202.mp4?alt=media&token=53d5b9ae-5b3a-4e8c-b874-dddbcfd956a1" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/course-slides%2FBATTERY%20TECHNOLOGY%20(%20LITHIUM%20ION).pdf?alt=media&token=679e53d0-2c08-42e1-b5a3-deadd5fda379" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
            </button>
            </div>

            <div class="col-md-6">
              <button type="submit"
              class="btn solarorange" 
              style="color: white; width: 50%;margin-bottom: 5%;"
              (click)="completed()"
             >Mark Completed</button>
          </div>
          </div>

        </div>
      </main>
  
    </div>
  </div>

