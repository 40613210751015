<app-navbar></app-navbar>
<div class="container">
    <div class="row">
      <div class="col-12">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                  <a href="#feag" class="nav-link active" role="tab" data-toggle="tab">
                      Female Engineering Academy Gallery
                  </a>
              </li>
              <li class="nav-item">
                  <a href="#dag" class="nav-link" role="tab" data-toggle="tab">
                      Driving Academy Gallery
                  </a>
              </li>
          </ul>
          <!-- Female Engineering Academy Gallery -->
          <div class="tab-content">
            <div class="tab-pane fade show active" id="feag">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="card">
                      <img src="../../../assets/images/DM.jpg" 
                      class="card-img-top img-size" alt="Orange-Bike">
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="card">
                      <img src="../../../assets/images/FEAgrad.jpg" 
                      class="card-img-top img-size" alt="Electric Car">
                  </div>
                </div>
              </div>
          
              <div class="row">
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/IMG_0742.jpg" 
                      class="card-img-top img-size" alt="Inside The Ark">
                  </div>
                </div>
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/SCS1.jpg" 
                      class="card-img-top img-size" alt="System Controller">
                  </div>
                </div>
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/Dorothy.JPG" 
                      class="card-img-top img-size" alt="Engineer working on an Electric car">
                  </div>
                </div>
              </div>
          
              <div class="row">
                  <div class="col-12 col-sm-7">
                    <div class="card">
                        <img src="../../../assets/images/practicaltraining.jpg" 
                        class="card-img-top img-size" alt="FEA Learning">
                    </div>
                  </div>
                  <div class="col-12 col-sm-5">
                    <div class="card">
                        <img src="../../../assets/images/Rebecca.JPG" 
                        class="card-img-top img-size" alt="Electric Car">
                    </div>
                  </div>
                </div>
          
                <div class="row">
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/BT1.jpg" 
                        class="card-img-top img-size" alt="Inside The Ark">
                    </div>
                  </div>
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/ES1.jpg" 
                        class="card-img-top img-size" alt="System Controller">
                    </div>
                  </div>
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/ES3.jpg" 
                        class="card-img-top img-size" alt="Engineer working on an Electric car">
                    </div>
                  </div>
                </div>        
            </div>

            <!-- Driving Academy Gallery -->
            <div class="tab-pane fade show active" id="dag">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="card">
                      <img src="../../../assets/images/RiderA1.jpg" 
                      class="card-img-top img-size" alt="Orange-Bike">
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="card">
                      <img src="../../../assets/images/RiderA2.jpg" 
                      class="card-img-top img-size" alt="Electric Car">
                  </div>
                </div>
              </div>
          
              <div class="row">
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/RiderA3.jpg" 
                      class="card-img-top img-size" alt="Inside The Ark">
                  </div>
                </div>
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/RiderA4.jpg" 
                      class="card-img-top img-size" alt="System Controller">
                  </div>
                </div>
                <div class="col-12 col-sm">
                  <div class="card">
                      <img src="../../../assets/images/RiderA5.jpg" 
                      class="card-img-top img-size" alt="Engineer working on an Electric car">
                  </div>
                </div>
              </div>
          
              <div class="row">
                  <div class="col-12 col-sm-7">
                    <div class="card">
                        <img src="../../../assets/images/RiderA7.jpg" 
                        class="card-img-top img-size" alt="FEA Learning">
                    </div>
                  </div>
                  <div class="col-12 col-sm-5">
                    <div class="card">
                        <img src="../../../assets/images/RiderA6.jpg" 
                        class="card-img-top img-size" alt="Electric Car">
                    </div>
                  </div>
                </div>
          
                <div class="row">
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/RiderA8.jpg" 
                        class="card-img-top img-size" alt="Inside The Ark">
                    </div>
                  </div>
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/7VL-Side-and-Back_.jpg" 
                        class="card-img-top img-size" alt="System Controller">
                    </div>
                  </div>
                  <div class="col-12 col-sm">
                    <div class="card">
                        <img src="../../../assets/images/Orange-Bike-Without-Screen-Side.jpg" 
                        class="card-img-top img-size" alt="Engineer working on an Electric car">
                    </div>
                  </div>
                </div>        
            </div>
          </div>
      </div>
</div>
