<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/user-courses">
                    <i class="fa fa-book" aria-hidden="true"></i>Introduction to Electric Vehicles
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/iot">
                    <i class="fa fa-book" aria-hidden="true"></i>Internet of Things 
                  </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/charger-installation">
                      <i class="fa fa-book" aria-hidden="true"></i>Charger Installation 
                    </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Installation 
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/welding">
                    <i class="fa fa-book" aria-hidden="true"></i>Welding 
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/battery-technology">
                    <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
          </nav>        
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">FEA- Solar Installation</h1>
          </div>


          <!-- second video -->
          <div class="row" id="solar">
            <div class="col-md-12">
              <h4><span>01  </span>Solar Installation Part 1</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FSolar-Installation-Blessings%2FBlessings%20(Solar%20Installation).mp4?alt=media&token=1cd4d5db-daac-4ba2-b5ad-de345d79fd29" type="video/mp4">
              </video>
            </div>
          </div>

            <!-- second video -->
            <div class="row" id="solar">
              <div class="col-md-12">
                <h4><span>02  </span>Solar Installation Part 2</h4>
                <video width="500" height="300" controls controlsList="nodownload">
                  <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FSolar-Installation-Blessings%2FBlessings%20(Solar%20Installation%201).mp4?alt=media&token=a9e82aaf-a607-402e-9aba-e084e66b6121" type="video/mp4">
                </video>
              </div>
            </div>

              <!-- second video -->
          <div class="row" id="solar">
            <div class="col-md-12">
              <h4><span>03  </span>Applications of Energy</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FSolar-Installation-Blessings%2FBlessings%20(Applications%20Of%20Energy).mp4?alt=media&token=c73ee5a1-7f8d-417d-858b-de8fd921e15c" type="video/mp4">
              </video>
            </div>
          </div>

            <!-- second video -->
            <!-- <div class="row" id="solar">
              <div class="col-md-12">
                <video width="500" height="300" controls controlsList="nodownload">
                  <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSolar-Power-Design%2FSFEA_Intro_fin_Gifty%204.mp4?alt=media&token=678e68c9-ebe8-40dd-b4ae-738e5be75d6e" type="video/mp4">
                </video>
              </div>
            </div> -->

          <div class="row">
            <div class="col-md-4">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FSolar-Installation-Blessings%2FCHARGER%20INSTALLATION%20-%20SOLAR%20INSTALLATION%20FINAL.pptx?alt=media&token=4c5324e8-3cf0-434d-a2b1-5ecf12aa219f" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn solarblue">
                <a href="https://forms.gle/nNidS4SiZp1qrrJY6" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Take Assessment
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <button type="submit"
              class="btn solarorange" 
              style="color: white; width: 50%;margin-bottom: 5%;"
              (click)="completed()"
             >Mark Completed</button>
          </div>

          </div>
  
        </div>
      </main>
  
    </div>
  </div>


