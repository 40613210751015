import { Component, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { newUser } from 'src/app/shared/model/newUser';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  fieldTextType: boolean;
  fieldTextType2: boolean;

  originalUser: newUser = {
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    userPwd: "",
    // subscribe: "",
  }

  newUser: newUser = {...this.originalUser}
  postError = false;
  postErrorMessage= "";

  constructor(
    private router: Router,
    public authService : AuthService
  ) { }

  ngOnInit(): void {
  }

         //Submit form validation
onSubmit(form: NgForm){
  console.log('in OnSubmit: ', form.valid);
// let txref = sessionStorage.getItem('transactionReference');

  if(form.valid){
    let webUser = {
    uid : "",
    userName: this.newUser.fullName,
    userEmail: this.newUser.emailAddress,
    phoneNumber: this.newUser.phoneNumber,
    userPassword: this.newUser.userPwd,
    photoURL: "",
    emailVerified: false,
    // txref: txref
    }
  console.log(webUser)
  this.authService.SetUserData(webUser);
  this.authService.SignUp(webUser.userEmail, webUser.userPassword);
  // console.log(webUser)
  // alert("Successfully logged in");
  // alert(`Successfully signed up. 

  // After signing up/registering, kindly check your email account inbox or spam folder for a message from SolarTaxi requesting you to verify your email address used for the registration.

  // You can only log in and see content only when you click on the link to verify your email address.`);
  form.reset();
  this.router.navigate(['/home']);
  }
  else{
    this.postError = true;
    this.postErrorMessage = "Please fix the above errors"
  }
}

onBlur(field : NgModel){
  console.log('in onBlur: ', field.valid);
}

toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}

toggleFieldTextType2() {
  this.fieldTextType2 = !this.fieldTextType2;
}

}
