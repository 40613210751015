import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-auto-electrical-of-an-electric-scooter',
  templateUrl: './auto-electrical-of-an-electric-scooter.component.html',
  styleUrls: ['./auto-electrical-of-an-electric-scooter.component.css']
})
export class AutoElectricalOfAnElectricScooterComponent implements OnInit {

  data = {
    course : "Auto Electrical of an Electric Scooter"
  }

  constructor(
    private usersService : UsersService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      course2: this.data.course
    }
    await this.usersService.userProgress(courseCompleted);
   
    this.router.navigate(['/iot']);
  }

}
