<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/user-courses">
                    <i class="fa fa-book" aria-hidden="true"></i>Introduction to Electric Vehicles
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/battery-technology">
                    <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
          </nav>
                
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Female Engineers Academy - Speed Control System</h1>
          </div>


          <!-- second video -->
          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%201.mp4?alt=media&token=a56fa560-14d7-4ba2-b230-e250c6191f32" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%202.mp4?alt=media&token=01be34b4-29ca-4ff6-9fe9-bb49d08ecb65" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20erica%203.mp4?alt=media&token=3279fd1c-b3b0-42ae-9099-4a4495589833" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%204.mp4?alt=media&token=e743b277-8c44-490e-97ef-5235154a9e1a" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%205.mp4?alt=media&token=2baec00f-c987-4305-b39e-1a06450cf982" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%206.mp4?alt=media&token=d0b85a51-eca7-49f8-b5a5-b1e6611866f0" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="scs">
            <div class="col-md-12">
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/videos%2FSpeed-Control-System%2FSFEA_Intro_fin_%20Erica%207.mp4?alt=media&token=552f05ac-dfe3-4330-ac85-a188b51a4525" type="video/mp4">
              </video>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-6">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/course-slides%2FSPEED%20CONTROL%20SYSTEM.pdf?alt=media&token=75a5b663-0c6a-4d58-84d4-2f1f1ea8071b" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
            </button>
            </div>
            <div class="col-md-6">
              <button type="submit"
              class="btn solarorange" 
              style="color: white; width: 50%;margin-bottom: 5%;"
              (click)="completed()"
             >Mark Completed</button>
          </div>
          </div>

        </div>
      </main>
  
    </div>
  </div>


