import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { Course } from 'src/app/shared/model/Course';
import { CourseService } from 'src/app/shared/services/course.service';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {
  modules: Course[] = [];
  filteredModules: Course[] = [];
  category: string;
  id: string;
  courseId;
  course;
  values;

  constructor(
    private coursesService: CoursesService,
    private courseService: CourseService,
    private usersService: UsersService,
    private route: ActivatedRoute
    ) { 
      this.courseId = JSON.parse(localStorage.getItem('course'));
    if(this.courseId) this.courseService.getCourse(this.courseId).pipe(take(1))
    .subscribe(c => this.course = c)
    }

  ngOnInit() {
    this.populateCourses();

    this.usersService.totalUsers()
    .subscribe( values => this.values = values.length);
  }

  populateCourses() {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    this.coursesService.getAllModules(this.id)
    .pipe(switchMap(modules => {
      this.modules = modules
      console.log(modules)
      return this.route.queryParamMap
    }))
    .subscribe(params => {
      this.category = params.get('category');
      this.applyFilter();
    })
  }

  private applyFilter() {
    this.filteredModules = (this.category) ?
    this.modules.filter(p => p.category === this.category):
    this.modules;
  }
}
