<app-navbar></app-navbar>
<main>
    <div class="row bgpic">
        <div class="bgpicoverlay">
            
            <div class="col-12 col-sm-6 text">
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star"></span>
                <span class="fa fa-star"></span>
                <h1>Introduction to Electric Vehicle</h1>
                <p class="content">{{ course.description }}
                </p>
                <p class="content">
                    {{ course.descriptioncont }}
                </p>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <a href="https://paystack.com/pay/female-engineers-academy" 
                        class="btn enrollnowbtn">Enroll Now - GHC{{ course.price }}</a>
                    </div>
                    <div class="col-12 col-sm-6">
                        <button class="btn enrollnowbtn enroll"  
                        [routerLink]="['/courses/',course.id]">Go Back To Course</button>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    <div id="divcard" class="row">
        <!-- <div class="row"> -->
            <div class="col-3 offset-1 col-sm-3">
                <p>Enrollments</p>
                <h3>{{ values-4 }}</h3>
            </div>
            <div class="col-8 col-sm-4">
                <p>Module</p>
                <h3>{{ module.module }}</h3>                  
            </div>
            <div class="col-12 col-sm-4">
                <p>Skill Level</p>
                <h3>{{ course.skilllevel }}</h3>
            </div>
        <!-- </div> -->
    </div>
    <div class="seconddiv">
        <div class="courseoverview">
            <div class="col overview">
                <h2 style="text-align: left;color: #F99909;">Module Overview</h2>
                <p>{{ module.moduleoverview }}</p>
                <p> {{ module.moduleoverviewcont }}</p>
                <h3 style="text-align: left;color: #F99909;">Objectives:</h3>
                <ul *ngFor="let item of module.moduleobjectives | keyvalue">
                      <li>{{ item.value }}</li>
                </ul>
            </div>
        </div>
        <div class="curriculum">
            <h3 class="currihead">Module Curriculum</h3>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                    <div class="currivideo">
                        <div class="row">
                            <div class="col-1">
                                <i class="fa fa-caret-square-o-right" style="text-align: right;" aria-hidden="true"></i>
                            </div>
                            <div class="col-7">
                                <p style="text-align: left;">{{ course.coursename}}</p>
                            </div>
                            <div class="col-4">
                                <button class="btn solarorange"
                               (click)="watchVideo(mymodal)"
                                >Preview</button>
                                <ng-template #mymodal let-modal>
                                    <div class="modal-header">
                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                        <!-- The video -->
                                        <video width="450" height="400" controls>
                                            <source [src]="module.preview"
                                             type="video/mp4">
                                          </video>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn solarorange" (click)="modal.close('Save click')">Close</button>
                                    </div>
                                  </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
        <div class="courseinstructor">
            <div class="col">
                <h2 align="center">Course Instructor</h2>
                <div class="card">
                  <img *ngIf="module.courseinstructor.image" [src]="module.courseinstructor.image" alt="{{ module.courseinstructor.name }}" style="width:100%;">
                  <div class="container">
                    <h4><b>{{ module.courseinstructor.name }}</b></h4> 
                    <p>{{ module.courseinstructor.qualification }}</p> 
                  </div>
                </div>
            </div>
        </div>
    </div>
  </main>
