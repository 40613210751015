import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Course } from '../model/Course';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  courseCollectionRef: AngularFirestoreCollection<Course>

  constructor(
    private afs: AngularFirestore
  ) { }

  getAllCourses() {
    this.courseCollectionRef = this.afs.collection<Course>('courses');
    return this.courseCollectionRef.snapshotChanges()
    .pipe(map(snaps => {
      return snaps.map(snap => {
        const data = snap.payload.doc.data() as Course;
        const id = snap.payload.doc.id;
        return { id, ...data}
      })
    }))
  } 
  
  getAllModules(id) {
    localStorage.setItem('course', JSON.stringify(id));
    this.courseCollectionRef = this.afs.collection('courses').doc(id)
    .collection('engineeringmodules');
    return this.courseCollectionRef.snapshotChanges()
    .pipe(map(snaps => {
      return snaps.map(snap => {
        const data = snap.payload.doc.data() as any;
        const id = snap.payload.doc.id;
        return { id, ...data}
      })
    }))
  }   
}
