<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
      <nav class="col-md-2 d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link active" routerLink="/electric-cars">
                <i class="fa fa-book" aria-hidden="true"></i>Electric Cars
              </a>
            </li>
            <!-- Calling SignOut() Api from AuthService -->
            <li class="nav-item">
              <a class="nav-link" routerLink="/design-and-manufacturing">
                <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/auto-electrical">
                <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/iot">
                <i class="fa fa-book" aria-hidden="true"></i>Internet of Things
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/charger-installation">
                <i class="fa fa-book" aria-hidden="true"></i>Charger Installation 
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/solar-installation">
                <i class="fa fa-book" aria-hidden="true"></i>Solar Installation 
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/welding">
                <i class="fa fa-book" aria-hidden="true"></i>Welding 
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="/battery-technology">
                <i class="fa fa-book" aria-hidden="true"></i>Battery Technology
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/assessment-1">
                <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/electrical-system">
                <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/speed-control-system">
                <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/assessment-2">
                <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/solar-power-design">
                <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/workshop-safety">
                <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/assessment-3">
                <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
              </a>
            </li> -->
          </ul>
        </div>    
      </nav>
        
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">FEA- Electric Cars</h1>
          </div>
          <!-- intro video -->
          <div class="row" id="intro">
            <div class="col-md-12">
              <h4><span>01  </span>Introduction to Electric Cars</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FElectric-Vehicles-Cars-Belinda-Akaba%2FBelinda%20(Electric%20Vehicles).mp4?alt=media&token=843d3fbd-7b8b-421d-aa96-066f7a674152" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="intro">
            <div class="col-md-12">
              <h4><span>02  </span>Main Components of an Electric Car</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FElectric-Vehicles-Cars-Belinda-Akaba%2FBelinda%20(Main%20Components%20Of%20An%20Electric%20Vehicle).mp4?alt=media&token=7eb6c9d9-fa4f-4b41-a107-cbb43a224a73" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FElectric-Vehicles-Cars-Belinda-Akaba%2FElectric%20Vehicles.docx?alt=media&token=1d01405d-0f6f-4ea7-adfa-a26a521db6ce" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn solarblue">
                <a href="https://forms.gle/kXPaWLKQeRix9tx37" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Take Assessment
                </a>
              </button>
            </div>
            <div class="col-md-4">
                <button type="submit"
                class="btn solarorange" 
                style="color: white; width: 50%;margin-bottom: 5%;"
                (click)="completed()"
               >Mark Completed</button>
            </div>
          </div>
        
        </div>
      </main>
  
    </div>
  </div>