<app-navbar></app-navbar>
<main>
    <div class="stage">
        <div class="nextsstage">
            <button class="login" routerLink="/login">Log In</button>
            <button class="signup" routerLink="/signup">Sign Up</button>
            <p class="lsmessage">Create an account to enroll in courses</p>
            <form #form="ngForm" (ngSubmit)="onSubmit(form)"> 
                <input type="text" placeholder="First-Last Name"
                ngModel name="fullName" class="form-control"
                id="fullName" [(ngModel)]="newUser.fullName" #fullNameField="ngModel"
                [class.field-error]="form.submitted && fullNameField.invalid"
                (blur)="onBlur(fullNameField)" required>

                <div [hidden]="!form.submitted || fullNameField.valid"
                class="alert alert-danger">
                    Enter your full name
                </div>

                <input type="email" placeholder="Email Address" 
                ngModel name="emailAddress" class="form-control"
                id="emailAddress" [(ngModel)]="newUser.emailAddress" #emailAddressField="ngModel"
                [class.field-error]="form.submitted && emailAddressField.invalid"
                (blur)="onBlur(emailAddressField)" required>

                <div [hidden]="!form.submitted || emailAddressField.valid"
                class="alert alert-danger">
                    Enter your email address
                </div>

                <input type="text" placeholder="Phone Number" 
                ngModel name="phoneNumber" class="form-control"
                id="phoneNumber" [(ngModel)]="newUser.phoneNumber" #phoneNumberField="ngModel" 
                [class.field-error]="form.submitted && phoneNumberField.invalid"
                (blur)="onBlur(phoneNumberField)" required>

                <div [hidden]="!form.submitted || phoneNumberField.valid"
                class="alert alert-danger">
                    Enter your phone number
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <input type="password" placeholder="Password" 
                        ngModel name="userPwd" class="form-control pswinput"
                        id="userPwd" [(ngModel)]="newUser.userPwd" #userPwdField="ngModel"
                        [class.field-error]="form.submitted && userPwdField.invalid"
                        [type]="fieldTextType ? 'text' : 'password'"
                        (blur)="onBlur(userPwdField)" required>
                        <!-- <div class="input-group-append"> -->
                            <span class="input-group-text">
                                <i class="fa"
                                    [ngClass]="{
                                        'fa-eye-slash': !fieldTextType,
                                        'fa-eye': fieldTextType
                                    }"
                                (click)="toggleFieldTextType()"
                                ></i>
                            </span>
                        <!-- </div> -->
                        <div [hidden]="!form.submitted || userPwdField.valid"
                        class="alert alert-danger">
                            Enter your a strong password
                        </div>
                    </div>
                </div>
                               

                <div class="form-group">
                    <div class="input-group">
                        <input type="password" placeholder="Confirm Password"
                        ngModel name="userPwd" class="form-control pswinput"
                        id="userPwd" [(ngModel)]="newUser.userPwd" #userPwdField="ngModel"
                        [class.field-error]="form.submitted && userPwdField.invalid"
                        [type]="fieldTextType2 ? 'text' : 'password'" 
                        (blur)="onBlur(userPwdField)" required>
                        <!-- <div class="input-group-append"> -->
                            <span class="input-group-text">
                                <i class="fa"
                                    [ngClass]="{
                                        'fa-eye-slash': !fieldTextType2,
                                        'fa-eye': fieldTextType2
                                    }"
                                (click)="toggleFieldTextType2()"
                                ></i>
                            </span>
                        <!-- </div> -->
                        
                        <div [hidden]="!form.submitted || userPwdField.valid"
                        class="alert alert-danger">
                            Enter your a strong password
                        </div>
                    </div>
                </div>
                
                

                <!-- <input type="checkbox"
                ngModel name="subscribe" class="form-control checkinput"
                id="subscribe" [(ngModel)]="newUser.subscribe" #subscribeField="ngModel"
                [class.field-error]="form.submitted && subscribeField.invalid"
                (blur)="onBlur(subscribeField)" value="Yes">
                <label for="subscribe">Send me email on new Solar Academy Courses</label> <br>
                
                <div [hidden]="!form.submitted || subscribeField.valid"
                class="alert alert-danger">
                    Enter your full name
                </div> -->

                <button type="submit" class="registerbutton mt-3" 
                >Register</button>
            </form>
        </div>
    </div>
    
</main>