import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-assessment3',
  templateUrl: './assessment3.component.html',
  styleUrls: ['./assessment3.component.css']
})
export class Assessment3Component implements OnInit {
  data = {
    module : "Assessment 3"
  }

  constructor(
    private usersService : UsersService
  ) { 
    
  }

  ngOnInit(): void {
  }

  async completed() {
    
    let courseCompleted = {
      assessment3: this.data.module
    }
    await this.usersService.userProgress(courseCompleted);
   
    alert('Thank you for taking time to finish the course')
  }

}
