<app-coursenavbar></app-coursenavbar>
  <!-- Sidebar navigation -->
  <div class="container-fluid">
    <div class="row">
  
        <nav class="col-md-2 d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" routerLink="/electric-cars">
                    <i class="fa fa-book" aria-hidden="true"></i>Electric Cars
                  </a>
                </li>
                <!-- Calling SignOut() Api from AuthService -->
                <li class="nav-item">
                  <a class="nav-link" routerLink="/design-and-manufacturing">
                    <i class="fa fa-book" aria-hidden="true"></i>Design and Manufacturing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/auto-electrical">
                    <i class="fa fa-book" aria-hidden="true"></i>Auto Electrical of an Electric Scooter
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/iot">
                    <i class="fa fa-book" aria-hidden="true"></i>Internet of Things 
                  </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/charger-installation">
                      <i class="fa fa-book" aria-hidden="true"></i>Charger Installation 
                    </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-installation">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Installation 
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/welding">
                    <i class="fa fa-book" aria-hidden="true"></i>Welding 
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-1">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 1
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/electrical-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Electrical Systems
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/speed-control-system">
                    <i class="fa fa-book" aria-hidden="true"></i>Speed Control System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-2">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 2
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/solar-power-design">
                    <i class="fa fa-book" aria-hidden="true"></i>Solar Power Design
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/workshop-safety">
                    <i class="fa fa-book" aria-hidden="true"></i>Workshop Tools and Safety Precautions
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/assessment-3">
                    <i class="fa fa-book" aria-hidden="true"></i>Assessment 3
                  </a>
                </li> -->
              </ul>
            </div>
        </nav>
                
  
      <!-- Main content -->
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">FEA - Electric Charger Installation</h1>
          </div>


          <!-- second video -->
          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>01  </span>Electric Charger Installation</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FCharger-Installation-Erica-Dakwa%2FErica%20(Electric%20Charger%20Installation).mp4?alt=media&token=71f2b200-4184-487c-b913-2c3d39500ea5" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row" id="desma">
            <div class="col-md-12">
              <h4><span>02  </span>How Charging Stations Are Powered</h4>
              <video width="500" height="300" controls controlsList="nodownload">
                <source src="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FCharger-Installation-Erica-Dakwa%2FErica%20(How%20Charging%20Stations%20Are%20Powered).mp4?alt=media&token=6724e225-6ae6-4d2e-9ddc-0712693fee97" type="video/mp4">
              </video>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <button class="btn solarorange">
                <a href="https://firebasestorage.googleapis.com/v0/b/solar-academy-18d4c.appspot.com/o/FEA-Videos-Cohort-3%2FCharger-Installation-Erica-Dakwa%2FCHARGER%20INSTALLATION%20-%20SOLAR%20INSTALLATION%20FINAL.pptx?alt=media&token=02ce86ef-be5e-4637-bee1-82ac540c2324" style="color: white;"
                target="_blank" rel="noopener noreferrer">
                    Download Course Material
                </a>
              </button>
            </div>
            <div class="col-md-4">
              <p>
                Assessment is after watching Solar Installation Videos
              </p>
            </div>
            <div class="col-md-4">
              <button type="submit"
              class="btn solarorange" 
              style="color: white; width: 50%;margin-bottom: 5%;"
              (click)="completed()"
             >Mark Completed</button>
          </div>
          </div>
        </div>
      </main>
    </div>
  </div>
