<app-navbar></app-navbar>
<div class="forgotpwd">
    <div>
      <div>
        <h3 style="color: #0D96E2;">Reset Password</h3>
  
        <p style="color:#787878">Please enter your email address to request a password reset.</p>
  
        <div class="formGroup">
          <input type="email" class="formControl" placeholder="Email Address" #passwordResetEmail required>
        </div>
  
        <!-- Calling ForgotPassword from AuthService Api -->
        <div class="formGroup">
          <input type="submit" class="btn btnPrimary" style="color:#0D96E2" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">
        </div>
      </div>
  
      <div class="redirectToLogin" style="color:#787878">
        <span>Go back to ? <span class="redirect" routerLink="/login" style="color: #0D96E2;">Log In</span></span>
      </div>
  
    </div>
  </div>
